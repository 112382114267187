import React, { Component } from 'react';
import { IonContent, IonLabel, IonPage, IonButton } from '@ionic/react';

import { Plugins } from '@capacitor/core';
import { FacebookLoginResponse } from '@rdlabo/capacitor-facebook-login';
const { FacebookLogin } = Plugins;

class Tab2 extends Component {
  state: any = {};
  props: any = {};

  constructor(props: any) {
    super(props);
    this.state = {
      token: null,
      userId: null,
      userInfo: null
    };
  };

  async getCurrentState(): Promise<boolean> {
    const result = await Plugins.FacebookLogin.getCurrentAccessToken();
    try {
      return result && result.accessToken;
    } catch (e) {
      return false;
    }
  }

  async signIn(): Promise<void> {
    const { history } = this.props;
    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
    const result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    if (result && result.accessToken) {
      console.log(result);
      this.setState({
        ...this.state,
        token: result.accessToken.token,
        userId: result.accessToken.userId
      });
      history.push({
        pathname: '/home',
        state: { token: result.accessToken.token, userId: result.accessToken.userId }
      });
    }
  }

  async getUserInfo() {
    // const response = await fetch(`https://graph.facebook.com/${this.props.location.state.userId}?fields=id,name,gender,link,picture&type=large&access_token=${this.props.location.state.token}`);
    const response = await fetch(`https://graph.facebook.com/${this.state.userId}?fields=id,name,gender,link,picture&type=large&access_token=${this.state.token}`);
    const myJson = await response.json();
    this.setState({
      ...this.state,
      user: myJson
    })
  }

  render() {
      
    return (
      <IonPage>
        <IonContent>
  
          <IonLabel>
             { this.state.token ? "Token: " + this.state.token : "Not logged in"}
          </IonLabel>

          <IonButton className="login-button" onClick={() => this.signIn()} expand="full" fill="solid" color="primary">
            Login with Facebook
          </IonButton>
  
        </IonContent>
      </IonPage>
    );
  };
};

export default Tab2;